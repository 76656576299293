.button {
  border-radius: 4px;
  background: var(--primary-500, #67bdfb);
  padding: 10px 16px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    background: var(--primary-600, #4fa9f8);
  }

  &:disabled {
    background: var(--primary-500, #67bdfb);
    opacity: 0.5;
    cursor: default;
  }
}
