.input {
  border-radius: 6px;
  border: 1px solid var(--primary-500, #67bdfb);
  padding: 8px 16px;

  color: var(--primary-900, #082f4c);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  &::placeholder {
    color: var(--secondary-300, #b1b2be);
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}
